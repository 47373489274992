/* You can add global styles to this file, and also import other style files */
@import 'qima-styles';

@import 'vendors/qima-vendors';

@include qima-font-faces('/ngx-qima/assets/');

body {
  background: $qima-color-neutral-smoke;

  .app-root {
    max-width: qima-pixels-to-em(768);
    margin: 0 auto;
  }
}
